import React from "react";
import { ArrowIcon, Container, Header, HeaderWrapper, Content, FutureProfits, FuturePayments, Info, FutureTransactions, ExpectedBalance, WalletIcon, CardIcon, IconWrapper } from "./styles";

export const Projection = () => {

    return (
        <Container>
            <Header>
                <HeaderWrapper>
                    <span>Projeção</span>
                    <div>
                        <p>7 dias</p>
                        <ArrowIcon/>
                    </div>
                </HeaderWrapper>
            </Header>
            <Content>
                <FutureTransactions>
                    <FutureProfits>
                        <Info>
                            <span>Recebimentos Futuros</span>
                            <p>R$ 5.234,02</p>
                        </Info>
                        <IconWrapper>
                            <WalletIcon/>
                        </IconWrapper>
                    </FutureProfits>
                    <FuturePayments>
                        <Info>
                            <span>Pagamentos Futuros</span>
                            <p>R$ 4.231,85</p>
                        </Info>
                        <IconWrapper>
                            <CardIcon/>
                        </IconWrapper>
                    </FuturePayments>
                </FutureTransactions>
                <ExpectedBalance>
                    <span>R$ 6.521,70</span>
                    <p>Saldo previsto para o dia 08/02</p>
                </ExpectedBalance>
            </Content>

        </Container>
    )
}
