import React from "react";
import { 
    Container,
    Header,
    OrdersWrapper,
    Order,
    IconWrapper,
    Icon,
    InfoOrder,
    Balance,
    OrderDetails,
    BalanceDetails,
    Type,
    Name,
    ArrowIcon
} from "./styles";

import FoodIconSVG from '../../assets/orderFood.svg'

export const Orders = () => {

    return (
        <Container>
            <Header>
                <span>Últimos lançamentos</span>
                <p>Ver extrato completo</p>
            </Header>
            <OrdersWrapper>
                
                <Order>
                    <OrderDetails>
                        <IconWrapper>
                            <Icon src={FoodIconSVG} />
                        </IconWrapper>
                        <InfoOrder>
                            <Type>Debito</Type>
                            <Name>Starbucks</Name>
                        </InfoOrder>
                    </OrderDetails>
                    <BalanceDetails>
                        <Balance>
                        -R$ 32,30
                        </Balance>
                        <ArrowIcon/>
                    </BalanceDetails>
                </Order>
                <Order>
                    <OrderDetails>
                        <IconWrapper>
                            <Icon src={FoodIconSVG} />
                        </IconWrapper>
                        <InfoOrder>
                            <Type>Debito</Type>
                            <Name>Starbucks</Name>
                        </InfoOrder>
                    </OrderDetails>
                    <BalanceDetails>
                        <Balance>
                        -R$ 32,30
                        </Balance>
                        <ArrowIcon/>
                    </BalanceDetails>
                </Order>
                <Order>
                    <OrderDetails>
                        <IconWrapper>
                            <Icon src={FoodIconSVG} />
                        </IconWrapper>
                        <InfoOrder>
                            <Type>Debito</Type>
                            <Name>Starbucks</Name>
                        </InfoOrder>
                    </OrderDetails>
                    <BalanceDetails>
                        <Balance>
                        -R$ 32,30
                        </Balance>
                        <ArrowIcon/>
                    </BalanceDetails>
                </Order>

            </OrdersWrapper>
        </Container>
    )
}
