import React from "react";
import { Sidebar } from "../../components/Siderbar";
import { 
     Container,
     Content,
     Header,
     SectionInfo,
     SectionIcon,
     SectionTitle,
     DashboardInfo,
     ClientInfo,
     ArrowIcon,
     AccountStats,
     Card,
     CardAccountInfo,
     Transactions,
     Items,
     ItemTitle,
     Action,
     ChargeIcon,
     BillIcon,
     TransferIcon,
     BellIcon,
    } from "./styles";

import HomeIconSVG from '../../assets/home.svg'
import ArrowIconSVG from '../../assets/arrow.svg'
import { Orders } from "../../components/orders";
import { Projection } from "../../components/projection";
import { Contacts } from "../../components/contacts";

export const Dashboard = () => {


    return (
        <Container>
            <Sidebar/>
            <Content>
                <Header>
                    <SectionInfo>
                        <SectionIcon src={HomeIconSVG} />
                        <SectionTitle>Início</SectionTitle>
                    </SectionInfo>
                    <DashboardInfo>
                        <BellIcon/>
                        <ClientInfo>
                            <div>
                                <span>Martin Ferramentas</span>
                                <p>32.241.521/0001-23</p>  
                            </div>
                            <ArrowIcon src={ArrowIconSVG} />
                        </ClientInfo>
                    </DashboardInfo>
                </Header>
                <AccountStats>
                    <Card className="balance">
                        <p>Saldo na conta</p>
                        <span>R$ 18.231,24</span>
                    </Card>
                    <Card className="profitability">
                    <p>Rentabilidade do Mês</p>
                        <span>+ R$ 3.911,95</span>
                    </Card>
                    <Card className="bills">
                        <p>Boletos em aberto</p>
                        <span>R$ 7.289,92</span>
                    </Card>
                    <CardAccountInfo>
                        <div>
                            <p className="name">
                            Martins Ferramentas LTDA
                            </p>
                            <p className="id">
                            32.241.521/0001-23
                            </p>
                            <p className="bankCode">
                            Banco Stric: <strong>141</strong> 
                            </p>
                            <p className="agency">
                            Agência: <strong>0001</strong> 
                            </p>
                            <p className="account">
                            Conta: <strong>312463712-4</strong> 
                            </p>
                        </div>
                    </CardAccountInfo>
                </AccountStats>

                 <Transactions>
                    <Orders/>
                    <Projection/>
                    <Contacts/>
                    <Items>
                        <Action>
                            <ItemTitle>Cobrar</ItemTitle>
                            <ChargeIcon/>
                        </Action>
                        <Action>
                            <ItemTitle>Pagar contas</ItemTitle>
                            <BillIcon/>
                        </Action>
                        <Action>
                            <ItemTitle>Transferir</ItemTitle>
                            <TransferIcon/>
                        </Action>
                    </Items>
                </Transactions>

            </Content>
        </Container>
    )
}