import styled, { css } from "styled-components";

import {ReactComponent as ChargeIconSVG} from "../../assets/charge.svg"
import {ReactComponent as BillIconSVG} from "../../assets/bill.svg"
import {ReactComponent as TransferIconSVG} from "../../assets/transfer.svg"
import {ReactComponent as BellIconSVG} from "../../assets/bell.svg"


export const Container = styled.div`

height: 100vh;
display: grid;
grid-template-columns: 256px 1fr;
grid-template-areas: 'sidebar content';

`

export const Content = styled.div`

grid-area: content;
background-color: pink;
display: flex;
flex-direction: column;

`

export const Header = styled.header`

height: 80px;
padding: 0 32px;
background-color: var(--white);

display: flex;
align-items: center;
justify-content: space-between;

position: sticky;
top: 0;
left: 0;
right: 0;
z-index: 2;

`
export const SectionInfo = styled.div`

display: flex;
align-items: center;

`

export const SectionIcon = styled.img`

`


export const SectionTitle = styled.span`

color: var(--gray-dark);
font-size: 18px;
font-family: var(--regular);
margin-left: 16px;

`


export const DashboardInfo = styled.div`

display: flex;
align-items: center;

`

export const ClientInfo = styled.div`

margin-left: 32px;
height: 56px;
width: 200px;
padding: 10px 16px;
border: 1px solid var(--gray-light-line);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: space-between;

> div {
    width: 240px;

    > span {
        font-size: 12px;
        color: var(--gray);
        font-family: var(--semiBold);
    }
    > p {
        font-size: 16px;
        color: var(--gray-light-text);
        font-family: var(--light);
        margin-top: -5px;
    }
}

@media (min-width: 1000px){
    
    > div {
        width: 280px;

        > span {
            font-size: 14px;
            color: var(--gray);
            font-family: var(--semiBold);
        }
        > p {
            font-size: 14px;
            color: var(--gray-light-text);
            font-family: var(--light);
            margin-top: -5px;
        }
    }
}

@media (min-width: 1280px){
    
        > div {
        > span {
            font-size: 16px;
            color: var(--gray);
            font-family: var(--semiBold);
        }
        > p {
            font-size: 16px;
            color: var(--gray-light-text);
            font-family: var(--light);
            margin-top: -5px;
        }
    }
}

`

export const ArrowIcon = styled.img`

height: 7px;

`
export const AccountStats = styled.div`

width: 100%;
min-height: 192px;
padding: 32px;
gap: 32px;
background-color: var(--gray-background);

/* display: grid;
grid-template-columns: repeat(4, 1fr); */

display: flex;
flex-wrap: wrap;

`

export const Card = styled.div`

flex: 1;
max-width: 50%;
height: 128px;
border-radius: 8px;
padding: 14px 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
position: relative;

&::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
}

> p {
    font-family: var(--light);
    font-size: 12px;
    color: var(--white);

    }
    > span {
        font-family: var(--semiBold);
        font-size: 24px;
        color: var(--white);
    }

&.balance {
    background-color: var(--cian);
}
&.profitability {
    background-color: var(--green);
}
&.bills {
    background-color: var(--white);

    > p, span {
        color: var(--gray);
    }
}

@media (min-width: 1000px){
    
    > p {
    font-family: var(--light);
    font-size: 14px;
    color: var(--white);

    }
    > span {
        font-family: var(--semiBold);
        font-size: 28px;
        color: var(--white);
    }
}

@media (min-width: 1280px){
    
    > p {
    font-family: var(--light);
    font-size: 16px;
    color: var(--white);

    }
    > span {
        font-family: var(--semiBold);
        font-size: 32px;
        color: var(--white);
    }
}

`
export const CardAccountInfo = styled.div`

height: 128px;
border-radius: 8px;
padding: 12px 32px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: var(--white-light-5);

> div {
    > p {
    font-size: 12px;
    color: var(--white);

    &.name {
        font-family: var(--semiBold);
    }
    &.id {
        font-family: var(--light);
    }
    &.bankCode {
        font-family: var(--light);
    }
    &.agency {
        font-family: var(--light);
    }
    &.account {
        font-family: var(--light);
        }
    }
}

@media (min-width: 1000px){
    
    > div {
        > p {
        font-family: var(--light);
        font-size: 14px;
        color: var(--white);
        }
    }
}

@media (min-width: 1280px){
    
    > div {
        > p {
        font-family: var(--light);
        font-size: 16px;
        color: var(--white);
        }
    }
}

`

export const Transactions = styled.div`

flex: 1;
background-color: var(--background);
padding: 32px;
gap: 32px;
display: grid;
// grid-template-columns: 1fr 1fr 1fr 1fr;

grid-template-columns: 1fr 1fr 1fr;
grid-template-areas:
'orders orders orders'
'projection projection contacts'
'items items items';

@media (min-width: 1280px){
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    'orders orders projection contacts'
    'orders orders items items';
}

`



export const Contacts = styled.div`
background-color: green;
grid-area: contacts;

`

export const Projection = styled.div`
background-color: blue;
grid-area: projection;

`

export const Items = styled.div`
grid-area: items;

display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: minmax(176px, 1fr);
grid-gap: 32px;

`

export const ItemTitle = styled.span`

font-size: 16px;
font-family: var(--semiBold);
color: var(--gray);

`

export const Action = styled.div`

background-color: var(--white);
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

position: relative;

&::after {
    content: '';
    width: 16px;
    height: 4px;
    background-color: var(--cian);
    position: absolute;
    top: 0;
}

`

const iconStyle = css`

fill: var(--cian);
width: 64px;
margin-top: 19px;

`

export const ChargeIcon = styled(ChargeIconSVG)`${iconStyle}`
export const BillIcon = styled(BillIconSVG)`${iconStyle}`
export const TransferIcon = styled(TransferIconSVG)`${iconStyle}`
export const BellIcon = styled(BellIconSVG)``
