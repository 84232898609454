import React from 'react';
import { Dashboard } from './pages/dashboard';
import { GlobalStyle } from './styles/globalStyle';

function App() {
  return (
    <>
      <Dashboard/>
      <GlobalStyle/>
    </>
  );
}

export default App;
