import styled, { css } from "styled-components";

import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'
import {ReactComponent as WalletIconSVG} from '../../assets/Wallet.svg'
import {ReactComponent as CardIconSVG} from '../../assets/card.svg'

const iconStyle = css`
    fill: var(--gray);
    width: 16px;
`

export const IconWrapper = styled.div`

width: 40px;
height: 40px;
background-color: var(--gray-light-05);
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;

`

export const WalletIcon = styled(WalletIconSVG)`${iconStyle}`
export const CardIcon = styled(CardIconSVG)`${iconStyle}`

export const Container = styled.div`

grid-area: projection;
background-color: var(--white);
border-radius: 8px;
display: flex;
flex-direction: column;

`

export const HeaderWrapper = styled.div`

display: flex;
justify-content: space-between;
border-bottom: 1px solid var(--gray-light-line);
padding: 24px 32px;

> span {
    color: var(--gray);
    font-size: 16px;
    font-family: var(--semiBold);
}

> div {

    display: flex;
    align-items: center;

    > p {
    color: var(--gray);
    font-size: 14px;
    font-family: var(--regular);
    margin-right: 7px;

    }
}


`

export const Header = styled.div`

position: relative;

&::after{
    content: '';
    width: 4px;
    height: 16px;
    background-color: var(--cian);
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
}


`

export const ArrowIcon = styled(ArrowIconSVG)``


export const Content = styled.div`

flex: 1;

display: flex;
flex-direction: column;
justify-content: space-between;
padding: 32px;

`

export const FutureProfits = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
padding: 16px 0;
border-bottom: 1px solid var(--gray-light-line);

`

export const FuturePayments = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
padding: 16px 0;


`

export const Info = styled.div`


> span {

    color: var(--gray);
    font-size: 14px;
    font-family: var(--regular);

}
> p {

    color: var(--cian);
    font-size: 14px;
    font-family: var(--regular);

}

`

export const FutureTransactions = styled.div``

export const ExpectedBalance = styled.div`



> span {

    font-size: 20px;
    font-family: var(--regular);
    color: var(--cian);
    margin-bottom: 6px;

}

> p {

    font-size: 10px;
    font-family: var(--regular);
    color: var(--gray);

}

`