import React from "react";
import { 
    Container,
    WrapperHeader,
    InfoAccount,
    SideFix,
    SidebarIcon,
    BalanceInfoAccount,
    VisionIcon,
    OptionSidebarFix,
    IconWrapper,
    Service,
    ServiceHeader,
    ServiceTitle,
    StricLogo,
    Arrowicon,
    BankMoneyIcon,
    HomeIcon,
    ReceiptIcon,
    SignOutIcon,
    HelpIconIcon,
    AccountDeclarationIcon,
    TransferMoneyIcon,
    BillIcon,
    PixIcon,
    ChargeIcon,
    DepositIcon,
    TaxIcon
} from "./styles";




export const Sidebar = () => {

    return (
        <Container>
            <WrapperHeader>
                <SidebarIcon/>
                <StricLogo/>
            </WrapperHeader>
            <InfoAccount>
                <p>Saldo na conta</p>
                <BalanceInfoAccount>
                    <p>R$ 18.231,24</p>
                    <VisionIcon/>
                </BalanceInfoAccount>
            </InfoAccount>
            <SideFix>
                <OptionSidebarFix>
                    <div>  
                        <IconWrapper>
                            <HomeIcon/>
                        </IconWrapper>
                        <span>Inicio</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <ReceiptIcon/>
                        </IconWrapper>
                        <span>Extrato</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <BankMoneyIcon/>
                        </IconWrapper>
                        <span>Comprovantes</span>
                    </div>
                </OptionSidebarFix>
            </SideFix>

            <Service>
                <ServiceHeader>
                    <ServiceTitle>
                        Serviço Bancários
                    </ServiceTitle>
                    <Arrowicon/>
                </ServiceHeader>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <DepositIcon/>
                        </IconWrapper>
                        <span>Depositar</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <ChargeIcon/>
                        </IconWrapper>
                        <span>Cobrar</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <PixIcon/>
                        </IconWrapper>
                        <span>Realizar PIX</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <BillIcon/>
                        </IconWrapper>
                        <span>Pagar Contas</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <TransferMoneyIcon/>
                        </IconWrapper>
                        <span>Transferir TED/DOC</span>
                    </div>
                </OptionSidebarFix>
            </Service>
            
            <Service>
                <ServiceHeader>
                    <ServiceTitle>
                        Serviço Bancários
                    </ServiceTitle>
                    <Arrowicon/>
                </ServiceHeader>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <TaxIcon/>
                        </IconWrapper>
                        <span>Tarifas</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <AccountDeclarationIcon/>
                        </IconWrapper>
                        <span>Declaração de Conta</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <HelpIconIcon/>
                        </IconWrapper>
                        <span>Ajuda</span>
                    </div>
                </OptionSidebarFix>
                <OptionSidebarFix>
                    <div>
                        <IconWrapper>
                            <SignOutIcon/>
                        </IconWrapper>
                        <span>Sair</span>
                    </div>
                </OptionSidebarFix>
            </Service>

        </Container>
    )
}