import {createGlobalStyle} from "styled-components"

export const GlobalStyle = createGlobalStyle`

    :root {
        --gray-light-line: #E3E3E3;
        --gray-light-text: #A3A3A3;
        --gray-light-order: #B5B7BD;
        --cian-dark: rgba(0, 0, 0, .3);
        --gray: #142249;
        --gray-light-05: rgba(20, 34, 73, .05);
        --gray-background: #0E1833;
        --background: #F7F6F8;
        --gray-dark: #282A3A;
        --cian: #38D2D9;
        --white: #FFFFFF;
        --white-light: rgba(255, 255, 255, .3);
        --white-light-5: rgba(255, 255, 255, .05);
        --green: #49D294;
        --red: #E74C3C;
        --line: rgba(255, 255, 255, .1);

        --light: 'SoleilLt';
        --semiBold: 'SoleilSb';
        --bold: 'Soleil Bold';
        --regular: 'Soleil';
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        min-height: 100vh;
    }

    body {
        font-family: 'Soleil';
        min-height: 100vh;
        -webkit-font-smoothing: antialiased;
    }

`