import styled, { css } from "styled-components";

import {ReactComponent as StricLogoSVG} from '../../assets/stric_logo.svg'
import {ReactComponent as SidebarIconSVG} from '../../assets/sidebarIcon.svg'
import {ReactComponent as VisionIconSVG} from '../../assets/vision.svg'
import {ReactComponent as HomeIconSVG} from '../../assets/home.svg'
import {ReactComponent as ReceiptIconSVG} from '../../assets/receipt.svg'
import {ReactComponent as BankMoneySVG} from '../../assets/bankmoney.svg'
import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'

import {ReactComponent as signOutIconSVG} from '../../assets/sign-out.svg'
import {ReactComponent as HelpIconSVG} from '../../assets/help.svg'
import {ReactComponent as AccountDeclarationIconSVG} from '../../assets/accountDeclaration.svg'
import {ReactComponent as TaxIconSVG} from '../../assets/tax.svg'
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as ChargeIconSVG} from '../../assets/chargeSide.svg'
import {ReactComponent as DepositIconSVG} from '../../assets/deposit.svg'
import {ReactComponent as transferMoneyIconSVG} from '../../assets/transferSide.svg'


const iconStyle = css`

fill: var(--white-light);
width: 16px;

`

export const StricLogo = styled(StricLogoSVG)`
margin-left: 24px;
`

export const SidebarIcon = styled(SidebarIconSVG)`${iconStyle}`
export const VisionIcon = styled(VisionIconSVG)`${iconStyle}`
export const HomeIcon = styled(HomeIconSVG)`${iconStyle}`
export const ReceiptIcon = styled(ReceiptIconSVG)`${iconStyle}`
export const BankMoneyIcon = styled(BankMoneySVG)`${iconStyle}`
export const Arrowicon = styled(ArrowIconSVG)`${iconStyle}`

export const TransferMoneyIcon = styled(transferMoneyIconSVG)`${iconStyle}`
export const DepositIcon = styled(DepositIconSVG)`${iconStyle}`
export const ChargeIcon = styled(ChargeIconSVG)`${iconStyle}`
export const PixIcon = styled(PixIconSVG)`${iconStyle}`
export const BillIcon = styled(BillIconSVG)`${iconStyle}`
export const AccountDeclarationIcon = styled(AccountDeclarationIconSVG)`${iconStyle}`
export const HelpIconIcon = styled(HelpIconSVG)`${iconStyle}`
export const SignOutIcon = styled(signOutIconSVG)`${iconStyle}`
export const TaxIcon = styled(TaxIconSVG)`${iconStyle}`



export const Container = styled.div`

grid-area: sidebar;
background-color: var(--gray);
height: 100%;
width: 256px;
display: flex;
flex-direction: column;
padding-bottom: 32px;
position: fixed;
z-index: 1;
top: 0;
left: 0;
overflow-y: scroll;

::-webkit-scrollbar {
        visibility: hidden;
        display: none;
    } /* Chorme, Safari */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`

export const WrapperHeader = styled.div`

background-color: var(--cian-dark);

height: 80px;
display: flex;
align-items: center;
padding: 38px 24px;

`



export const SideFix = styled.div`

padding: 16px 24px;
display: flex;
flex-direction: column;


`
export const OptionSidebarFix = styled.div`

height: 24px;

> div {
    display: flex;

    > span {
    color: var(--white);
    font-size: 16px;
    font-weight: 100;
    font-family: var(--light);

    }
}

& + div {
    margin-top: 24px;
}

`

export const IconWrapper = styled.div`

display: flex;
justify-content: center;
margin-right: 24px;

`

export const Icon = styled.img`

width: 16px;

`


export const InfoAccount = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
height: 96px;
padding: 24px;
border-bottom: 2px solid var(--line);

> p {
    font-size: 16px;
    color: var(--cian);
    font-family: var(--light);
}

`

export const BalanceInfoAccount = styled.div`

display: flex;
align-items: center;
justify-content: space-between;

> p {
    font-size: 24px;
    color: var(--white);
    font-family: var(--semiBold);
}

`

export const Service = styled.div`

padding: 16px 24px;

`



export const ServiceHeader = styled.div`

display: flex;
justify-content: space-between;
margin-bottom: 36px;
align-items: center;

`



export const ServiceTitle = styled.p`

font-size: 16px;
color: var(--white);
font-family: var(--bold);
`

export const ServiceIcon = styled.img``
